import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"

export default props => {
  return (
    <Card className="card-container-meetup m-1 mb-3" as={Link} to={ props.show ? props.to : '#'}>
      <Card.Header className="d-flex justify-content-between">
        <h6
          style={{
            color: props.color,
            textTransform: "capitalize",
            fontWeight: "bold",
          }}
        >
          {props.type}
        </h6>
        {props.soon && (
          <div>
            <Button
              style={{
                fontSize: "13px",
              }}
              size="sm"
              variant="outline-warning btn-upcomming"
            >
              Bientôt
            </Button>
          </div>
        )}
      </Card.Header>
      <Card.Body className="pt-3">
        <Card.Title>
          <h4>{props.title}</h4>
        </Card.Title>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <span
          style={{
            fontSize: "13px",
            color: "#333333",
          }}
        >
          {props.place}
        </span>
        <h6
          style={{
            fontSize: "13px",
            color: "#8b8989",
            fontWeight: "bold",
          }}
        >
          {props.subtitle}
        </h6>
      </Card.Footer>
    </Card>
  )
}
