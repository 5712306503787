import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

export default () => {
  const { author } = useStaticQuery(query).site.siteMetadata
  return (
    <div className="footer-parent">
      <Container>
        <div className="footer text-muted p-2">
          <Row>
            <Col md="6">
              <span className="m-auto">
                {/* Codé &nbsp; */}
                {/* <span> par </span> &nbsp; */}
                <a href="https://www.regisatemengue.com/bio">{author}</a>
                &copy;{new Date().getFullYear()}.
              </span>
              <div>
                <span>Tous Droits Reservés.</span> &nbsp;
                {/* <a href="#">Politique de confidentialité</a> */}
              </div>
            </Col>
            <Col md="6">
              <div>
                <div className="d-md-inline-flex icons-container">
                  <a
                    href="https://www.youtube.com/@regisatemengue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      size="2x"
                      icon={["fab", "youtube"]}
                      className="icons youtube"
                      title="youtube"
                    />
                  </a>
                  <a
                    href="https://www.github.com/atemengue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      size="2x"
                      icon={["fab", "github"]}
                      className="icons github"
                      title="Github"
                    />
                  </a>
                  <a
                    href="https://linkedin.com/in/regis-atemengue/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      size="2x"
                      icon={["fab", "linkedin"]}
                      className="icons linkedin"
                      title="LinkedIn"
                    />
                  </a>
                  <a
                    href="https://www.twitter.com/regis_ate"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      size="2x"
                      icon={["fab", "twitter"]}
                      className="icons twitter"
                      title="Twitter"
                    />
                  </a>
                  <a
                    href="https://www.freecodecamp.org/regisatemengue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      size="2x"
                      icon={["fab", "free-code-camp"]}
                      className="icons fcc"
                      title="FreeCodeCamp"
                    />
                  </a>

                  <a
                    href="mailto:moafembe@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      size="2x"
                      icon={["fas", "envelope"]}
                      className="icons mail"
                      title="e-mail"
                    />
                  </a>
                  {/* <a href="../../resume.pdf" target="_blank" download>
                    <FontAwesomeIcon
                      size="2x"
                      icon={["fas", "file-alt"]}
                      className="icons file"
                      title="Resume"
                    />
                  </a> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
const query = graphql`
  query Author {
    site {
      siteMetadata {
        author
      }
    }
  }
`
