import React from "react"
import { Container } from "react-bootstrap"
import Footer from "./Footer"
import Header from "./Header"

export default ({ children, pageName }) => (
  <Container fluid className="px-0 theme-light app-container">
    <Header />
    <Container
      fluid
      className={`pt-5 mt-5 text-center min-vh-100 ${pageName} `}
    >
      {children}
    </Container>
    <Footer />
  </Container>
)
