import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import { Badge, Container } from "react-bootstrap"

export default props => {
  return (
    <Container>
      <Link to={props.to} style={{ textDecoration: "none" }}>
        <i style={{ textDecoration: "none", color: "gray" }}>{props.date}</i>
        <h2 className="mt-1">{props.title}</h2>
      </Link>
      {props.tags.map(tag => (
        <Badge key={tag} pill className="px-2 mr-1 badge-violet">
          {tag}
        </Badge>
      ))}
      <p className="pt-3 text-justify">{props.excerpt}</p>
      <p className=" d-flex align-items-center mt-2">
        {/* <FontAwesomeIcon
          size="sm"
          icon={["fas", "clock"]}
          className="icons mr-1"
        /> */}
        {props.time}
      </p>
      <hr />
    </Container>
  )

  //   <Card className="card-container" as={Link} to={props.to}>
  //     <Card.Img as={Img} fluid={props.featuredImage} className="h-50" />
  //     <Card.Body className="pt-3">
  //       <Card.Title>
  //         <h4>{props.title}</h4>
  //       </Card.Title>
  //       <Card.Subtitle className="mb-2 text-muted">
  //         {props.subtitle}
  //       </Card.Subtitle>
  //       <Card.Text>{props.excerpt}</Card.Text>
  //     </Card.Body>
  //   </Card>
  // )
}
