import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "gatsby"
import React, { useContext } from "react"
import { Container, Form, Nav, Navbar } from "react-bootstrap"
import ThemeContext from "../utils/theme"
import "./Fontawesome.js"

export default () => {
  const { dark, toggleDark, toString } = useContext(ThemeContext)
  return (
    <Navbar variant={toString()} fixed="top" collapseOnSelect expand="md">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <span className="logo">Régis Atemengue</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="pr-3 mr-4 nav-links">

            <Nav.Link
              className="ml-2"
              as={Link}
              to="/bio"
              title="A propos de moi"
            >
              Biographie
            </Nav.Link>
            <Nav.Link className="ml-2" as={Link} to="/blog" title="Blog">
              Blog
            </Nav.Link>
            {/* <Nav.Link
              className="ml-2"
              as={Link}
              to="/tutoriels"
              title="tutoriels"
            >
              tutoriels
            </Nav.Link> */}
            <Nav.Link
              className="ml-2"
              as={Link}
              to="/formations"
              title="Formations"
            >
              Formations
            </Nav.Link>
            <Nav.Link className="ml-2" as={Link} to="/mentor" title="Mentoring">
              Mentoring
            </Nav.Link>
            <Nav.Link className="ml-2" as={Link} to="/meetup" title="Meetup">
              Meetup
            </Nav.Link>
            <Nav.Link className="ml-2" as={Link} to="/projets" title="Projets">
              Projets
            </Nav.Link>
            <Nav.Link className="ml-2" as={Link} to="/cv" title="CV">
              CV
            </Nav.Link>
            <Nav.Link
              className="ml-2"
              as={Link}
              to="/contacts"
              title="Contacts"
            >
              Contacts
            </Nav.Link>
            <Nav.Link
              className="ml-2"
              as={Link}
              target="_blank"
              href="https://www.youtube.com/@regisatemengue"
              title="Youtube Channel"
            >
              <FontAwesomeIcon
                size="xl"
                icon={["fab", "youtube"]}
              />
            </Nav.Link>

            <Form className="ml-3 my-auto">
              <div className="switch-wrapper">
                <label id="switch" className="switch" htmlFor="checkbox">
                  <input
                    checked={dark}
                    onChange={toggleDark}
                    type="checkbox"
                    id="checkbox"
                  />
                  <div id="slider" className="slider round"></div>
                </label>
              </div>
            </Form>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
