import { Img, Link } from "gatsby"
import React from "react"
import Card from "react-bootstrap/Card"

export default props => {
  return (
    <Card className="card-container m-1 mb-3" as={Link} to={props.to}>
      <Card.Img
        as={Img}
        src={props.featuredImage.src}
        className="card-img-new"
      />
      <Card.Body className="pt-3">
        <Card.Title>
          <h4>{props.title}</h4>
        </Card.Title>

        <Card.Text>{props.excerpt}</Card.Text>
      </Card.Body>
    </Card>
  )
}
